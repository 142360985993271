import { useAuth0 } from '@auth0/auth0-react'
import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QueryKeys } from '../../types'
import { getTrainingsFromOrganisationUsers, getUserTrainings, setUserTrainingStatus } from './trainings'
import { Training, TrainingStatistics, TrainingStatus } from './types'
import { TrainingId } from '@estercare/ester-shared'
import { sendErrorToSentry } from '../../../../utils/sentry'

export const useGetUserTrainingsQuery = (): UseQueryResult<Training[], Error> => {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery({
    queryKey: [QueryKeys.Trainings, 'private'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently()
      return await getUserTrainings({ accessToken })
    },
  })
}

export const useTrainingStatusMutation = () => {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()

  return useMutation({
    mutationKey: [QueryKeys.Trainings, 'private', 'postTrainingStatus'],
    mutationFn: async ({ status, trainingId }: { status?: TrainingStatus; trainingId?: string }) => {
      const accessToken = await getAccessTokenSilently()
      if (!status) throw new Error('Missing required status')
      if (!trainingId) throw new Error('Missing required trainingId')
      return await setUserTrainingStatus({ accessToken, trainingData: { status, trainingId } })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Trainings, 'private'] })
    },
    onError: (error) => {
      sendErrorToSentry(error)
      throw new Error(`Failed to update training ${error}`)
    },
  })
}

export const useGetTrainingStatistics = (trainingId?: TrainingId): UseQueryResult<TrainingStatistics, Error> => {
  const { getAccessTokenSilently } = useAuth0()

  return useQuery({
    queryKey: [QueryKeys.Trainings, 'organisation', 'private'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently()
      if (!trainingId) throw new Error('Missing required trainingId')
      return await getTrainingsFromOrganisationUsers({ accessToken, trainingId })
    },
  })
}
