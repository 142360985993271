import { IArticleFields, IArticleTextBlock, ILinkBlock, getRelatedContent, isArticle } from '@estercare/ester-shared'
import { useGetArticleBySlug, useGetEntriesByContentType } from '../../../api/contentful/contentfulQueries'
import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { Title3 } from '../../../components/styles/Typography'
import styled from 'styled-components'
import { theme } from '../../../theme/theme'
import { useParams } from 'react-router-dom'
import { RichText } from '../../../components/richText/RichText'
import { Header } from '../../../components/Header'
import { PreviewGuard } from '../../../components/PreviewGuard'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { Author } from '../../../components/Author'
import { ArticleList } from '../../../components/ArticleList'
import { ListenToPodcastButton } from '../../../components/ListenToPodcastButton'

type ArticleContent = IArticleTextBlock | ILinkBlock

const hasTypeTextBlock = (item: ArticleContent): item is IArticleTextBlock => {
  return Object.prototype.hasOwnProperty.call(item.fields, 'text')
}

const hasTypeLink = (item: ArticleContent): item is ILinkBlock => {
  return Object.prototype.hasOwnProperty.call(item.fields, 'url')
}

export const Article = () => {
  const slug = useParams()
  const articleQuery = useGetArticleBySlug(slug?.id || '')
  const article = articleQuery?.data?.items[0]

  const allArticles = useGetEntriesByContentType<IArticleFields>('article').data?.items

  if (articleQuery.isLoading) return <LoadingSpinner />
  if (!isArticle(article)) return <PreviewGuard />

  const { abstract, articleContent, primaryImage, title, medicalReviewer } = article.fields

  const allArticlesNoMini = allArticles && allArticles.filter((article) => !article.fields.isMiniArticle)
  const relatedArticles = allArticlesNoMini
    ? getRelatedContent(article, allArticlesNoMini, 4)?.map((entry) => entry.fields)
    : null

  return (
    <>
      {isArticle(article) && title && (
        <>
          <Header title={title} description={abstract} image={primaryImage} backButtonLink="/portal/kunskapsbank" />
          <ComponentLayout>
            <ContentContainer>
              {articleContent?.map((item: ArticleContent, index) => {
                if (hasTypeTextBlock(item) && item.fields.text) {
                  return (
                    <ItemContainer key={index}>
                      <RichText richText={item.fields.text} />
                    </ItemContainer>
                  )
                }
                if (hasTypeLink(item)) {
                  const { label, linkText, url } = item.fields
                  if (!label || !linkText || !url) return null
                  return (
                    <PodcastContainer key={index}>
                      <ListenToPodcastButton key={index} subtitle={linkText} href={url} />
                    </PodcastContainer>
                  )
                }
                return null
              })}
              <ItemContainer> {medicalReviewer && <Author {...medicalReviewer.fields} />}</ItemContainer>
            </ContentContainer>
          </ComponentLayout>
          {relatedArticles && (
            <ComponentLayout backgroundColor={theme.color.beigeDark}>
              <RelatedArticlesContainer>
                <StyledTitle3>Relaterade artiklar</StyledTitle3>
                <ArticleList articles={relatedArticles as IArticleFields[]} />
              </RelatedArticlesContainer>
            </ComponentLayout>
          )}
        </>
      )}
    </>
  )
}

const ItemContainer = styled.div`
  width: 100%;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${theme.breakpoint.medium}px) {
    padding-left: 15%;
    padding-right: 15%;
  }
`

const PodcastContainer = styled.div`
  padding-top: ${theme.spacing.medium}px;
  padding-bottom: ${theme.spacing.large}px;
`

const RelatedArticlesContainer = styled.div`
  padding-top: ${theme.spacing.xxlarge}px;
`

const StyledTitle3 = styled(Title3)`
  text-align: center;
  margin-bottom: ${theme.spacing.mediumLarge}px;
`
