import { useState, ChangeEvent } from 'react'
import { FormTypes, FormData, FormError } from './types'
import { useDetailUserMutation } from '../../api/ester-b2b-api/private/users/userQueries'
import { sendErrorToSentry } from '../../utils/sentry'

export const useSupplementaryDataForm = (initialData: FormData, userId: string | null, onSuccess: () => void) => {
  const [formData, setFormData] = useState<FormData>(initialData)
  const [formErrors, setFormErrors] = useState<FormError[]>()
  const { mutate: updateUserDetailMutation } = useDetailUserMutation()

  const handleInputChange = (field: FormTypes) => (event: ChangeEvent<HTMLInputElement> | string) => {
    if (formErrors && formErrors.find((error) => error.formType === field)) {
      setFormErrors((prev) => prev?.filter((error) => error.formType !== field))
    }
    const value = typeof event === 'string' ? event : event.target.value

    if (field === FormTypes.GENDER) {
      setFormData((prev) => ({ ...prev, [field]: value as 'M' | 'F' | 'U' | '' }))
    } else {
      setFormData((prev) => ({ ...prev, [field]: value.trim() }))
    }
  }

  const formValidation = () => {
    let isValid = true
    const newErrors: FormError[] = []

    if (
      formData.birthYear.length !== 4 ||
      !(formData.birthYear.startsWith('19') || formData.birthYear.startsWith('20'))
    ) {
      newErrors.push({ formType: FormTypes.BIRTH_YEAR, errorMessage: 'Felaktigt format på födelseår' })
      isValid = false
    }

    if (formData.zipCode.length !== 5 || isNaN(Number(formData.zipCode))) {
      newErrors.push({ formType: FormTypes.ZIP_CODE, errorMessage: 'Felaktigt format på postnummer' })
      isValid = false
    }

    setFormErrors(newErrors)
    return isValid
  }

  const onSubmit = () => {
    const isValid = formValidation()
    if (isValid && userId) {
      updateUserDetailMutation(
        {
          newUserData: {
            birthYear: Number(formData.birthYear),
            zipCode: Number(formData.zipCode),
            gender: formData.gender as 'M' | 'F' | 'U',
          },
        },
        { onSuccess, onError: (error) => sendErrorToSentry(error) }
      )
    }
  }

  return { formData, formErrors, handleInputChange, onSubmit }
}
