import { FC, useState } from 'react'
import styled from 'styled-components'
import { OnClickButton } from './buttons/onclick/OnClickButton'
import { PreviewGuard } from './PreviewGuard'
import { IArticleFields } from '@estercare/ester-shared'
import { Title6 } from './styles/Typography'
import { BlockItem } from './BlockItem'
import { CollectionCard } from './CollectionCard'
import { theme } from '../theme/theme'
import { Grid } from './Grid'
import { splitArrayInto2and4Chunks } from '../utils/arrays'
import { TextLink } from './TextLink'
import { useWindowWidth } from '../hooks/useWindowWidth'
import { isMobile, isTablet } from '../utils/devices'

interface Props {
  articles: IArticleFields[]
  title?: string
  backgroundColor?: string
  showAsArchive?: boolean
}

export const ArticleList: FC<Props> = ({ articles, title, showAsArchive = false }) => {
  const width = useWindowWidth()
  const isMobileDevice = isTablet(width) || isMobile(width)

  const loadMoreArticlesCount = 18
  const startingArticlesShownCount = 6

  const [articleCount, setArticleCount] = useState(startingArticlesShownCount)
  const shownArticles = articles.slice(0, articleCount)

  const articlesInChunks = splitArrayInto2and4Chunks(shownArticles)
  const numberOfChunks = articleCount / 3
  const moreArticlesToShow = articleCount <= shownArticles.length

  const renderArticleCard = (article: IArticleFields, index: string | number, isHorizontal: boolean) => {
    const { slug, title, abstract, primaryImage } = article
    const label = 'Artikel'
    if (!slug || !title || !abstract || !primaryImage) return <PreviewGuard key={index} />
    const height = isHorizontal ? 215 : 368

    return (
      <BlockItem key={index} height={height} gridColumn={isHorizontal && !isMobileDevice ? 'span 2' : 'span 1'}>
        <CollectionCard
          horizontal={isHorizontal}
          slug={`/portal/kunskapsbank/artiklar/${slug}`}
          title={title}
          abstract={abstract}
          label={label}
          image={primaryImage}
        />
      </BlockItem>
    )
  }

  const renderArticleChunk = (chunk: IArticleFields[], index: number) => {
    const isHorizontal = chunk.length === 2 && showAsArchive
    return chunk.map((article, articleIndex) => renderArticleCard(article, `${index}-${articleIndex}`, isHorizontal))
  }

  const articleCollectionsHorizontal = articlesInChunks
    .slice(0, numberOfChunks)
    .filter((chunk) => chunk.length === 2 && showAsArchive)
    .flatMap(renderArticleChunk)

  const articleCollectionsVertical = articlesInChunks
    .slice(0, numberOfChunks)
    .filter((chunk) => chunk.length !== 2 && showAsArchive)
    .flatMap(renderArticleChunk)

  return (
    <>
      {title && <Title6>{title}</Title6>}
      <StyledGridHorizontal $columns={isMobileDevice ? 2 : 4}>{articleCollectionsHorizontal}</StyledGridHorizontal>
      <StyledGridVertical $columns={isMobileDevice ? 2 : 4}>{articleCollectionsVertical}</StyledGridVertical>

      {showAsArchive && moreArticlesToShow ? (
        <ButtonContainer>
          <OnClickButton
            text="Fler artiklar"
            onClick={() => setArticleCount((prevCount) => prevCount + loadMoreArticlesCount)}
            secondary
          />
        </ButtonContainer>
      ) : (
        <LinkContainer>
          <TextLink text="Till vår kunskapsbank" href="/portal/kunskapsbank" />
        </LinkContainer>
      )}
    </>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: ${theme.spacing.xxlarge}px;
`
const LinkContainer = styled.div`
  display: flex;
  margin-top: ${theme.spacing.large}px;
  justify-content: flex-end;
`

const StyledGridHorizontal = styled(Grid)`
  margin-top: ${theme.spacing.medium}px;
  gap: ${theme.spacing.xsmall}px;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;

  @media screen and (max-width: ${theme.breakpoint.large}px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    grid-template-columns: 1fr;
  }
`

const StyledGridVertical = styled(Grid)`
  margin-top: ${theme.spacing.medium}px;
  gap: ${theme.spacing.xsmall}px;
  grid-template-columns: repeat(4, minmax(230px, 1fr));
  justify-content: space-between;

  @media screen and (max-width: ${theme.breakpoint.xlarge}px) {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }

  @media screen and (max-width: ${theme.breakpoint.small}px) {
    grid-template-columns: repeat(1, minmax(200px, 350px));
    justify-content: center;
  }
`
