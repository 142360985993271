import { useState, useCallback, useEffect } from 'react'

export enum SessionStorageKey {
  ARTICLE_FILTER = 'article_filter',
}

export const useSessionStorage = <T extends string | number | boolean | null>(
  key: SessionStorageKey,
  initialValue?: T
) => {
  const readValue = useCallback((): T | null => {
    const item = sessionStorage.getItem(key)
    if (item !== null) {
      return JSON.parse(item)
    }
    return initialValue ?? null
  }, [key, initialValue])

  const [storedValue, setStoredValue] = useState<T | null>(readValue)

  useEffect(() => {
    setStoredValue(readValue())
  }, [readValue])

  const setValue = useCallback(
    (value: T) => {
      setStoredValue(value)
      sessionStorage.setItem(key, JSON.stringify(value))
    },
    [key]
  )

  return [storedValue, setValue] as const
}
