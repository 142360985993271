import React, { useEffect, useState } from 'react'
import {
  useSelectUserGender,
  useSelectUserZipcode,
  useSelectUserBirthYear,
  useSelectFirstName,
  useSelectUserId,
} from '../../state/selectors'
import { Modal } from '../Modal'
import { BodyLargeMedium, BodySmall, Title3 } from '../styles/Typography'
import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { OnClickButton } from '../buttons/onclick/OnClickButton'
import { useSupplementaryDataForm } from './useSupplementaryDataForm'
import { BirthYearInput, GenderInput, ZipCodeInput } from './formInputs'
import { FormTypes, SelectOption } from './types'

export const SupplementaryDataModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const userId = useSelectUserId()
  const userGender = useSelectUserGender()
  const userZipCode = useSelectUserZipcode()
  const userBirthYear = useSelectUserBirthYear()
  const firstName = useSelectFirstName()

  const { formData, formErrors, handleInputChange, onSubmit } = useSupplementaryDataForm(
    {
      birthYear: userBirthYear?.toString() || '',
      gender: userGender || '',
      zipCode: userZipCode?.toString() || '',
    },
    userId || null,
    () => setShowModal(false)
  )

  const genderOptions: SelectOption[] = [
    { value: 'F', label: 'Kvinna' },
    { value: 'M', label: 'Man' },
    { value: 'U', label: 'Föredrar att inte uppge' },
  ]

  useEffect(() => {
    if (!userGender || !userZipCode || !userBirthYear) {
      setShowModal(true)
    }
  }, [userBirthYear, userGender, userZipCode])

  if (!showModal) return null

  return (
    <Modal disableClose>
      <StyledTitle3>Hej, {firstName}!</StyledTitle3>
      <BodyLargeMedium>Välkommen till EsterCare För Företag</BodyLargeMedium>
      <BodySmall>För att komma igång med EsterCare behöver vi veta lite mer om dig.</BodySmall>
      <BirthYearInput
        value={formData.birthYear}
        onChange={handleInputChange(FormTypes.BIRTH_YEAR)}
        error={formErrors?.find((error) => error.formType === FormTypes.BIRTH_YEAR)?.errorMessage}
      />
      <GenderInput value={formData.gender} onChange={handleInputChange(FormTypes.GENDER)} options={genderOptions} />
      <ZipCodeInput
        value={formData.zipCode}
        onChange={handleInputChange(FormTypes.ZIP_CODE)}
        error={formErrors?.find((error) => error.formType === FormTypes.ZIP_CODE)?.errorMessage}
      />
      <ButtonWrapper>
        <OnClickButton
          disabled={!formData.birthYear || !formData.gender || !formData.zipCode}
          buttonColor={theme.color.plumMid}
          text="Fortsätt"
          onClick={onSubmit}
        />
      </ButtonWrapper>
    </Modal>
  )
}

const StyledTitle3 = styled(Title3)`
  && {
    margin-bottom: ${theme.spacing.xsmall}px;
  }
`

const ButtonWrapper = styled.div`
  margin-top: ${theme.spacing.mediumLarge}px;
  button {
    width: 100%;
  }
`
