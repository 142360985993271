import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { App } from './App'
import { Auth0ProviderWithNavigate } from './components/auth/Auth0ProviderWithNavigate'
import { theme } from './theme/theme.ts'
import GlobalStyle from './components/styles/Global'
import * as Sentry from '@sentry/react'
import { muiTheme } from './components/styles/MuiStyles.ts'

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<div>Something went wrong 😭</div>}>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <GlobalStyle />
            <BrowserRouter>
              <Auth0ProviderWithNavigate>
                <App />
              </Auth0ProviderWithNavigate>
            </BrowserRouter>
          </QueryClientProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)
