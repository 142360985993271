import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGetTrainingBySlug } from '../../../api/contentful/contentfulQueries'
import { PreviewGuard } from '../../../components/PreviewGuard'
import { theme } from '../../../theme/theme'
import styled from 'styled-components'
import { Arrow } from '../../../components/icons/Arrow'
import { CloseTrainingModal } from '../../../components/TrainingModal'
import { useWindowWidth } from '../../../hooks/useWindowWidth'
import { isDesktop, isTablet } from '../../../utils/devices'
import { TrainingStatus } from '../../../api/ester-b2b-api/private/trainings/types'
import {
  useGetUserTrainingsQuery,
  useTrainingStatusMutation,
} from '../../../api/ester-b2b-api/private/trainings/trainingQueries'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { Title2 } from '../../../components/styles/Typography'
import { Success } from '../../../components/icons'

interface TrainingMessage {
  type: string
  courseId: string
  data?: number
}
interface IFrameProgressMessageEvent extends MessageEvent {
  data: TrainingMessage
}

export const Training = () => {
  const slug = useParams()
  const { isLoading, data } = useGetTrainingBySlug(slug?.id || '')
  const course = data?.items[0]?.fields

  const width = useWindowWidth()
  const isBigScreen = isDesktop(width) || isTablet(width)

  const { mutate: trainingStatusMutation } = useTrainingStatusMutation()
  const { data: userTrainingData } = useGetUserTrainingsQuery()

  const currentTraining =
    Array.isArray(userTrainingData) &&
    userTrainingData?.find((training) => training.trainingId === course?.trainingIdentifier)

  const trainingCompleted = currentTraining && currentTraining?.status === TrainingStatus.COMPLETED

  const [progress, setProgress] = useState(0)
  const [showCloseModal, setShowCloseModal] = useState(false)
  const [isLoadingTraining, setIsLoadingTraining] = useState(true)

  const isTrainingInProgress = progress > 0 && progress < 100 && !trainingCompleted

  useEffect(() => {
    const handleReceiveMessage = (event: IFrameProgressMessageEvent) => {
      if (event.origin !== 'https://ester-public-assets.s3.eu-north-1.amazonaws.com') return
      if (event.data.type === 'ready') {
        setIsLoadingTraining(false)
      }
      if (trainingCompleted) return
      if (event.data.type === 'progress') {
        event.data.data && setProgress(event.data.data)
      }
    }
    window.addEventListener('message', handleReceiveMessage)

    return () => {
      window.removeEventListener('message', handleReceiveMessage)
    }
  }, [trainingCompleted])

  useEffect(() => {
    if (progress === 100 && !trainingCompleted) {
      trainingStatusMutation({
        status: TrainingStatus.COMPLETED,
        trainingId: course?.trainingIdentifier,
      })
    }
    if (isTrainingInProgress) {
      trainingStatusMutation({
        status: TrainingStatus.IN_PROGRESS,
        trainingId: course?.trainingIdentifier,
      })
    }
  }, [progress, trainingCompleted, trainingStatusMutation, course, isTrainingInProgress])

  useEffect(() => {
    if (isTrainingInProgress) {
      window.history.pushState(null, '', window.location.href)
    }

    // Warns the user when reloading the page if the training is in progress
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isTrainingInProgress) {
        event.preventDefault()
        setShowCloseModal(true)
      }
    }

    // Warns the user when using the browser's back button if the training is in progress
    const handlePopState = (event: PopStateEvent) => {
      if (isTrainingInProgress) {
        event.preventDefault()
        setShowCloseModal(true)
        window.history.pushState(null, '', window.location.href)
      }
    }

    window.addEventListener('popstate', handlePopState)
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('popstate', handlePopState)
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isTrainingInProgress, progress])

  const handleBackClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (progress !== 0 && progress !== 100) {
      e.preventDefault()
      setShowCloseModal(true)
    }
  }

  if (isLoading) return <LoadingSpinner color={theme.color.plum} size={60} />
  if (!course) return <PreviewGuard />

  return (
    <>
      {isBigScreen && (
        <InnerContainer>
          <Link to="/portal/utbildning" onClick={handleBackClick}>
            <BackButton>
              <Arrow color={theme.color.black} size={14} left />
            </BackButton>
          </Link>
        </InnerContainer>
      )}
      {trainingCompleted && (
        <HeaderContainer>
          <TextContainer>
            <Title2>Du har genomfört utbildningen</Title2>
            <Success color={theme.color.green} size={60} />
          </TextContainer>
        </HeaderContainer>
      )}
      <CourseContainer $isLoading={isLoadingTraining}>
        <iframe
          id="course-iframe"
          src={course.link}
          width="100%"
          height="100%"
          style={{ border: 'none' }}
          title="Training Module"
        />
        {isLoadingTraining && <LoadingSpinner color={theme.color.plum} size={60} />}
      </CourseContainer>
      {showCloseModal && (
        <CloseTrainingModal
          onClose={() => setShowCloseModal(false)}
          path="/portal/utbildning"
          courseTitle={course.title}
        />
      )}
    </>
  )
}

const HeaderContainer = styled.div`
  background-color: ${theme.color.beigeDark};
  position: relative;
  max-width: 1440px;
  height: 0;
  z-index: 100;
  padding: 0;
  animation: slideInFromTop 0.5s ease-out forwards;
  @keyframes slideInFromTop {
    0% {
      height: 0;
      padding: 0;
      opacity: 0;
    }
    100% {
      height: auto;
      padding: ${theme.spacing.xlarge}px 0;
      opacity: 1;
    }
  }
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (min-width: ${theme.breakpoint.medium}px) {
    margin-left: 120px;
    h2 {
      margin-right: ${theme.spacing.medium}px;
    }
  }
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    margin: 0 ${theme.spacing.medium}px;
    h2 {
      width: 80%;
    }
  }
`
const CourseContainer = styled.div<{ $isLoading: boolean }>`
  iframe {
    display: ${(props) => (props.$isLoading ? 'none' : 'block')};
  }
  max-width: 100%;
  height: 100%;
`

const InnerContainer = styled.div`
  position: fixed;
  margin: ${theme.spacing.xxlarge}px 0 0 ${theme.spacing.xxlarge}px;
  max-width: 1620px;
  box-sizing: content-box;
  z-index: 1000;
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    padding: ${theme.spacing.small}px 0 0 ${theme.spacing.small}px;
  }
`

const BackButton = styled.div`
  background-color: ${theme.color.white};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${theme.spacing.mediumLarge}px;
  padding-top: ${theme.spacing.tiny}px;
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 3px 9px 0px rgba(0, 0, 0, 0.05);
`
