import { FC } from 'react'
import { BodyLarge } from './styles/Typography'
import { env } from '../utils/environments/environmentVariables'
import { sendErrorToSentry } from '../utils/sentry'

export const PreviewGuard: FC = () => {
  if (env.isProd('VITE_NODE_ENV')) {
    sendErrorToSentry(`PreviewGuard: Missing required attributes at ${window.location.href}`)
    return null
  } else {
    // eslint-disable-next-line no-console
    console.log('Rendering component fallback')
    return <BodyLarge>Missing required attributes</BodyLarge>
  }
}
