import { useAuth0 } from '@auth0/auth0-react'
import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QueryKeys } from '../../types'
import { getActiveServices, requestServiceActivation } from './services'
import { Services } from '@estercare/ester-shared'
import { sendErrorToSentry } from '../../../../utils/sentry'
import { useIsUserSet } from '../../../../state/selectors'

export const useGetActiveServicesQuery = (): UseQueryResult<Services[], Error> => {
  const { getAccessTokenSilently } = useAuth0()
  const isUserFetched = useIsUserSet()

  return useQuery({
    queryKey: [QueryKeys.Services, 'private'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently()
      return await getActiveServices({ accessToken })
    },
    enabled: isUserFetched,
  })
}

export const useRequestServiceActivationMutation = () => {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()

  return useMutation({
    mutationKey: [QueryKeys.HealthCalls, 'private', 'postActivationRequest'],
    mutationFn: async ({ serviceName }: { serviceName: Services }) => {
      const accessToken = await getAccessTokenSilently()
      if (!serviceName) throw new Error('Missing required serviceName')
      return await requestServiceActivation({ accessToken, serviceData: { serviceName } })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.HealthCalls, 'private'] })
    },
    onError: (error) => {
      sendErrorToSentry(error)
      throw new Error(`Failed to update training ${error}`)
    },
  })
}
