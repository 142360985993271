import styled from 'styled-components'
import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { Title3 } from '../../../components/styles/Typography'
import { theme } from '../../../theme/theme'
import { useParams } from 'react-router-dom'
import { PreviewGuard } from '../../../components/PreviewGuard'
import { ImageTextBlock } from '../../../components/ImageTextBlock'
import { useGetEntriesByContentType, useGetPodcastBySlug } from '../../../api/contentful/contentfulQueries'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { IArticleFields, IPodcastEpisodeFields, getRelatedContent, isPodcast } from '@estercare/ester-shared'
import { ListenToPodcastButton } from '../../../components/ListenToPodcastButton'
import { Header } from '../../../components/Header'
import { PodcastList } from '../../../components/PodcastList'

export const Podcast = () => {
  const { id } = useParams()
  if (!id) throw new Error('No slug provided')

  const podcastQuery = useGetPodcastBySlug(id)
  const podcastEpisode = podcastQuery?.data?.items[0]
  const allPodcastEpisodes = useGetEntriesByContentType<IPodcastEpisodeFields>('podcastEpisode').data?.items

  if (podcastQuery.isLoading) return <LoadingSpinner />
  if (!isPodcast(podcastEpisode)) return <PreviewGuard />

  const { episodeNumber, title, description, featuredImage, spotifyEpisodeId } = podcastEpisode.fields
  if (!description || !featuredImage || !title) return <PreviewGuard />

  const relatedPodcastEpisodes = allPodcastEpisodes
    ? getRelatedContent(podcastEpisode, allPodcastEpisodes, 4)?.map((entry) => entry.fields)
    : null

  return (
    <>
      <Header
        title={`Avsnitt ${episodeNumber}: ${title}`}
        backButtonLink="/portal/kunskapsbank"
        backgroundColor={theme.color.plum}
        textColor={theme.color.plumlight}
      />
      <ImageTextBlock
        imageUrl={`https:${featuredImage.fields.file.url}`}
        imageOrientation="left"
        title="Om avsnittet"
        text={description}
      />
      <ComponentLayout backgroundColor={theme.color.beigeDark}>
        <PodcastButtonContainer>
          <ListenToPodcastButton
            subtitle={`Avsnitt ${episodeNumber}: ${title}`}
            href={`https://open.spotify.com/episode/${spotifyEpisodeId}`}
            width="562px"
          />
        </PodcastButtonContainer>
      </ComponentLayout>
      {relatedPodcastEpisodes && (
        <ComponentLayout backgroundColor={theme.color.beigeLight}>
          <StyledTitle3>Relaterade Poddar</StyledTitle3>
          <PodcastList podcasts={relatedPodcastEpisodes as IArticleFields[]} title="" />
        </ComponentLayout>
      )}
    </>
  )
}

const PodcastButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledTitle3 = styled(Title3)`
  text-align: center;
  margin-bottom: ${theme.spacing.mediumLarge}px;
`
