import React from 'react'
import styled from 'styled-components'
import { Input } from '../Input'
import { SelectInput } from '../SelectInput'
import { BodySmall } from '../styles/Typography'
import { theme } from '../../theme/theme'
import { SelectOption } from './types'

export const BirthYearInput: React.FC<{
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
}> = ({ value, onChange, error }) => (
  <InputWrapper>
    <StyledInput label="Födelseår" placeholder="YYYY" value={value} onChange={onChange} />
    {error && <StyledErrorText>{error}</StyledErrorText>}
  </InputWrapper>
)

export const GenderInput: React.FC<{
  value: string
  onChange: (value: string) => void
  options: SelectOption[]
}> = ({ value, onChange, options }) => (
  <InputWrapper>
    <SelectInput
      backgroundColor="beigeLight"
      label="Biologiskt kön"
      options={options}
      value={value}
      onChange={onChange}
      placeholder="Välj ett alternativ"
    />
  </InputWrapper>
)

export const ZipCodeInput: React.FC<{
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
}> = ({ value, onChange, error }) => (
  <InputWrapper>
    <StyledInput label="Postnummer till din bostad" placeholder="12345" value={value} onChange={onChange} />
    {error && <StyledErrorText>{error}</StyledErrorText>}
  </InputWrapper>
)

const InputWrapper = styled.div`
  margin-top: ${theme.spacing.mediumLarge}px;
`

const StyledInput = styled(Input)`
  background-color: ${theme.color.beigeLight};

  span {
    text-transform: capitalize;
  }
`

const StyledErrorText = styled(BodySmall)`
  color: ${theme.color.red};
`
