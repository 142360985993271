import styled from 'styled-components'
import { theme } from '../theme/theme'
import { BodyLarge, Title2 } from './styles/Typography'
import { SuperImage } from './SuperImage'
import { Asset } from 'contentful'
import { Grid } from './Grid'
import { Arrow } from './icons/Arrow'
import { Link } from 'react-router-dom'

interface Props {
  backButtonLink?: string
  backgroundColor?: string
  textColor?: string
  image?: Asset
  title: string
  description?: string
  button?: JSX.Element
  noPaddingBottom?: boolean
  maxWidth?: number
}

export const Header = ({
  backButtonLink,
  backgroundColor,
  textColor,
  image,
  title,
  description,
  button,
  noPaddingBottom,
  maxWidth,
}: Props) => {
  const marginBottom = Boolean(button)

  return (
    <Container $backgroundColor={backgroundColor}>
      <Grid $columns={3} $gridGap={0}>
        <InnerContainer $maxWidth={maxWidth} $noPaddingBottom={noPaddingBottom}>
          {backButtonLink && (
            <Link to={backButtonLink}>
              <BackButton>
                <Arrow color={theme.color.black} size={14} left />
              </BackButton>
            </Link>
          )}
          <StyledTitle2 $textColor={textColor}>{title}</StyledTitle2>
          {description && <StyledBodyLarge $marginBottom={marginBottom}>{description}</StyledBodyLarge>}
          {button && button}
        </InnerContainer>
        {image && (
          <ImageContainer>
            <SuperImage asset={image} fill />
          </ImageContainer>
        )}
      </Grid>
    </Container>
  )
}

const Container = styled.div<{ $backgroundColor?: string }>`
  background-color: ${({ $backgroundColor }) => ($backgroundColor ? $backgroundColor : theme.color.beigeDark)};
  position: relative;
  max-width: 1440px;
  height: auto;
  z-index: 100;
`

const InnerContainer = styled.div<{ $noPaddingBottom?: boolean; $maxWidth?: number }>`
  padding: ${theme.spacing.xlarge}px;
  padding-bottom: ${({ $noPaddingBottom }) => ($noPaddingBottom ? 0 : null)};
  grid-column: 1 / span 2;
  max-width: ${({ $maxWidth }) => $maxWidth && `${$maxWidth}px`};
  box-sizing: content-box;
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    padding: ${theme.spacing.large}px;
    padding-bottom: ${({ $noPaddingBottom }) => ($noPaddingBottom ? 0 : null)};
  }
`

const BackButton = styled.div`
  background-color: ${theme.color.white};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${theme.spacing.mediumLarge}px;
  padding-top: ${theme.spacing.tiny}px;
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 3px 9px 0px rgba(0, 0, 0, 0.05);
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: right;
  max-height: 400px;

  @media screen and (max-width: ${theme.breakpoint.large}px) {
    max-height: ${theme.breakpoint.large}px;
    justify-content: center;
  }
`

const StyledTitle2 = styled(Title2)<{ $textColor?: string }>`
  margin-bottom: ${theme.spacing.xsmall}px;
  color: ${({ $textColor }) => ($textColor ? $textColor : theme.color.black)};
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
`

const StyledBodyLarge = styled(BodyLarge)<{ $marginBottom?: boolean }>`
  margin-bottom: ${({ $marginBottom }) => ($marginBottom ? theme.spacing.mediumLarge : 0)}px;
`
